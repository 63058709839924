export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  pink: '#FF007F',
  blue: '#004EFF',
  babyBlue: '#00BCF5',
  purple: '#7A00E3',
  gray: '#7F7F7F',
  darkGray: '#A7A7A7',
  grayLight: '#E0E0E0',
  'off-white': '#f8f8f8',
  error: '#FF0000',
  olive: '#787020',
  darkOlive: '#645D21',
  navyBlue: '#00263A',
  lightNavyBlue: '#003057',
  altBlue: '#003B4D',
  orange: '#AC441E',
  darkOrange: '#812C0D',
  lightOrange: '#FAF8F0',
  beige: '#E7DDB4'
}

export const gradients = {
  triColorGradient: 'linear-gradient(112deg, ' + colors.pink + ' -5%,' + colors.purple + ' 41%,' + colors.babyBlue + ' 100%)',
  blueGradient: 'linear-gradient(135deg, ' + colors.navyBlue + ' 0%,' + colors.babyBlue + ' 100%)',
  lightBlueGradient: 'linear-gradient(135deg, ' + colors.babyBlue + ' 0%, #0070F2 100%)'
}