import styled from "styled-components";
import { colors } from "theme";
import { Link } from 'gatsby';

var __html = require('../modules/html/privacyHTML');
var privacyHTML = { __html: __html };

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 900px;
  margin-left: 16px;
`;

const LinkWrapper = styled.div`
  align-self: start;
  font-family: "forma-djr-text", Helvetica, sans-serif;
  font-weight: bold;
  position: sticky ;
  top: 64px;

  a { 
    color: ${colors.orange}
  }
`;

const PageWrapper = styled.div`
  position: relative ;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 64px 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: ${colors.lightOrange};
`;

const PrivacyPolicyPage = (props) => {
  return (
    <div>
      <PageWrapper>
        <LinkWrapper><Link to="/">Back to Home</Link></LinkWrapper>
       <Content dangerouslySetInnerHTML={privacyHTML} />
    </PageWrapper>
    </div>
  )
}

export default PrivacyPolicyPage;